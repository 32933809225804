import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Main text": "Instantly calculate the cost of manufacturing your part online!",
      "Dropzone header": "Upload your model:",
      "Dropzone": "Drag 'n' drop some STL files here, or click to select files",
      "Dropzone footer link": "Why do we only calculate STL files?",
      "Dropzone footer": " You can send any type of model to info@arcobo.tech for calculation.",
      "Quantity": "Quantity:",
      "Material": "Material",
      "User agreement": "I agree with the user terms",
      "Calculate price button": "Calculate price",
      "Confirm hint": "Please confirm the terms of information processing",
      "Confirm hint message": "Please confirm your agreement to the processing of information",
      "Warning": "WARNING",
      "Error": "ERROR",
      "Close": "CLOSE",
      "Please select STL": "Please select an STL model file.",
      "File": "File:",
      "Weight": "Weight:",
      "Volume": "Volume:",
      "Printing time": "Printing time:",
      "Order time": "Order time:",
      "External dimensions": "External dimensions:",
      "Quantity in order": "Quantity in order:",
      "Total price": "TOTAL PRICE",
      "Insert your email": "Enter your email",
      "Complete order": "ORDER",
      "Email is incorrect": "Email is incorrect",
      "Thank you": "THANK YOU!",
      "Order accepted": "Your order has been accepted. Wait for a response from our specialists for further communication.",
      "Selected alloy": "Selected alloy:"
    }
  },
  kz: {
    translation: {
      "Main text": "Өндірістің бөлігінің өндіріс құнын шұғылана жеткізеді!",
      "Dropzone header": "Модельді жүктеңіз:",
      "Dropzone": "STL файлдарын осы жерге сүйреп тастаңыз, немесе файлдарды таңдаңыз",
      "Dropzone footer link": "Негізінен STL файлдарын есептеймізбе?",
      "Dropzone footer": "Сіз модельдің кез келген түрін info@arcobo.tech-қа жіберуге болады бағалау үшін.",
      "Quantity": "Саны:",
      "Material": "Материал",
      "User agreement": "Пайдаланушы шарттарымен келісемін",
      "Calculate price button": "Құнын есептеу",
      "Confirm hint": "Ақпаратты өңдеу шарттарын растаңыз",
      "Confirm hint message": "Ақпаратты өңдеу шарттарына келісіміңізді растаңыз",
      "Warning": "ЕСКЕТУ",
      "Error": "ҚАТЕ",
      "Close": "ЖАБУ",
      "Please select STL": "STL модель файлын таңдаңыз.",
      "File": "Файл:",
      "Weight": "Салмақ:",
      "Volume": "Объем:",
      "Printing time": "Баспа уақыты:",
      "Order time": "Тапсырыс уақыты:",
      "External dimensions": "Сыртқы өлшемдер:",
      "Quantity in order": "Тапсырыстағы саны:",
      "Total price": "ЖАЛПЫ ҚYНА",
      "Insert your email": "Сіздің email:",
      "Complete order": "ТАПСЫРУ",
      "Email is incorrect": "Электронды пошта дұрыс емес",
      "Thank you": "РАХМЕТ!",
      "Order accepted": "Тапсырыс қабылданды. Более қарап шығу үшін біздің мамандарымыздан жауапты күтіңіз.",
      "Selected alloy": "Таңдалған құрама:"
    }
  },
  ru: {
    translation: {
      "Main text": "Рассчитайте стоимость изготовления Вашей детали прямо сейчас онлайн!",
      "Dropzone header": "Загрузите Вашу модель:",
      "Dropzone": "Перетащите сюда файл в формате STL, или нажмите для выбора файла",
      "Dropzone footer link": "Почему мы обрабатываем онлайн только файлы формата STL?",
      "Dropzone footer": " Вы можете отправить файлы другого типа на info@arcobo.tech для расчета стоимости изготовления.",
      "Quantity": "Количество:",
      "Material": "Материал",
      "User agreement": "Я согласен с условиями пользовательского соглашения",
      "Calculate price button": "Рассчитать",
      "Confirm hint": "Пожалуйста, подтвердите условия обработки информации",
      "Confirm hint message": "Пожалуйста, подтвердите свое согласие c пользовательским соглашением.",
      "Warning": "ВНИМАНИЕ",
      "Error": "ОШИБКА",
      "Close": "ЗАКРЫТЬ",
      "Please select STL": "Пожалуйста, выберите файл модели формата STL.",
      "File": "Файл:",
      "Weight": "Вес:",
      "Volume": "Объем:",
      "Printing time": "Время печати:",
      "Order time": "Срок:",
      "External dimensions": "Внешние габариты:",
      "Quantity in order": "Количество в заказе:",
      "Total price": "ОБЩАЯ СТОИМОСТЬ",
      "Insert your email": "Введите ваш email",
      "Complete order": "ЗАКАЗАТЬ",
      "Email is incorrect": "Email некорректен",
      "Thank you": "СПАСИБО!",
      "Order accepted": "Ваш заказ принят. Ожидайте ответа от наших специалистов для дальнейшей коммуникации.",
      "Selected alloy": "Выбранный сплав:"
    }
  }
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

  export default i18n;