import React, { FunctionComponent, useEffect, useCallback, useState } from "react";
import { Link } from 'react-router-dom';

import {
  Autocomplete,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { animateScroll as scroll } from 'react-scroll';
import { useDropzone } from 'react-dropzone';
import { BeatLoader } from 'react-spinners';

import styles from "./MainSection.module.css";
import { handleCalculateClickAction } from './handleCalculateClick';
import OrderFrame from "../components/OrderFrame";
import MessageFrame from "../components/MessageFrame";

import { useTranslation } from 'react-i18next';

const { v4: uuidv4 } = require('uuid');

const MainSection: FunctionComponent = () => {
  const { t, i18n } = useTranslation(); 

  const [language, setLanguage] = useState(i18n.language.toUpperCase());
  const [materialsLibrary, setMaterialsLibrary] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [modelsQuantity, setModelsQuantity] = useState(1);
  const [orderInformation, setOrderInformation] = useState(null);
  const [loadingActive, setLoadingActive] = useState(false);
  const [enablePriceFrame, setPriceFrameEnabled] = useState(false);
  const [calculationComplete, setCalculationComplete] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = React.useState<File | null>(null);

  const [isCheckboxCheck, setCheckboxChecked] = useState(false);
  const [hintMessage, setHintMessage] = useState(null);
  const [messageInformation, setMessageInformation] = useState(null);
  const [uuid, setUUID] = useState(uuidv4().toString());

  const onArcoboLogoImageClick = useCallback(() => {
    window.open("https://arcobo.tech");
  }, []);

  const handleModelsQuantityChange = (event) => {
    let inputValue = event.target.value;
  
    if (inputValue < 1) {
      inputValue = 1;
    } else if (inputValue > 99) {
      inputValue = 99;
    }
    setModelsQuantity(inputValue);
  }

  const handleMaterialChange = (event, value) => {
    setSelectedMaterial(value);
  };

  const handleCalculateClick = () => {
    if (file !== null) {
      if (isCheckboxCheck == false) {
        setHintMessage(t('Confirm hint message'));
      } else {
        if (hintMessage != null) {
          setHintMessage(null);
        }

        setLoadingActive(true);
        let requestParams = {
          uuid: uuid,
          modelsQuantity: modelsQuantity,
          selectedMaterial: selectedMaterial,
          language: i18n.language,
        };
        handleCalculateClickAction(requestParams, file, setOrderInformation, setCalculationComplete);
      }
    } else{
      let message = {
        messageHeader: t("Warning"),
        messageBody: t("Please select STL"),
        buttonText: t("Close"),
      };
      setMessageInformation(message);
    }
  };

  const triggerCancelOrder = () => {
    setPriceFrameEnabled(false);
    setOrderInformation(null);
    setSelectedFile(null);
    setFile(null);
  }

  const showMessage = (messsageContent: any) => {
    setMessageInformation(messsageContent);   
  }

  const onDrop = React.useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0].name);

    const file = acceptedFiles[0];
    setFile(file);
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: {'.stl': []}, multiple: false});

  // Page initial function
  useEffect(() => {
    fetch('/api/initial', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: uuid
      }),
    })
    .then(response => response.json())
    .then(data => {
      setMaterialsLibrary(data);
      setSelectedMaterial(data[0]);
    })
    .catch(error => console.error('Error:', error));
  }, []);  

  useEffect(() => {
    if (calculationComplete == true) {
      setLoadingActive(false);
      setCalculationComplete(false);

      if (orderInformation) {
        if(orderInformation.error == "") {
          if(orderInformation.warning != "") {
            let message = {
              messageHeader: t("WARNING"),
              messageBody: orderInformation.warning,
              buttonText: t("Close"),
            };
            setMessageInformation(message);
          }
          setPriceFrameEnabled(true);
          
        } else {
          let message = {
            messageHeader: t("ERROR"),
            messageBody: orderInformation.error,
            buttonText: t("Close"),
          };
          setMessageInformation(message);
          setPriceFrameEnabled(false);
          setOrderInformation(null);
          setSelectedFile(null);
          setFile(null);
        }
      }
    }
  }, [calculationComplete]);


  useEffect(() => {
    if (calculationComplete) {
      scroll.scrollToBottom({
        duration: 500,
        delay: 100,
        smooth: 'easeInOutQuart',
        containerId: 'orderFrame'
      });
    }
  }, [calculationComplete]);

  return (
    <div className={styles.mainSection}>
      <div className={styles.pageHeaderFrame}>
        <div className={styles.languageFrame}>
        <Autocomplete
            className={styles.languageselector}
            disablePortal
            options={["EN", "KZ", "RU"]}
            onChange={(event, value) => {
              setLanguage(value);
              i18n.changeLanguage(value.toLowerCase());
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                color="primary"
                label="Language"
                variant="standard"
                placeholder=""
                helperText=""
                inputProps={{ ...params.inputProps, readOnly: true }}
              />
            )}
            clearOnEscape={false}
            disableClearable={true}
            value={language}
            size="medium"
          />
        </div>
        <div className={styles.pageHeaderFrameChild} />
        <img
          className={styles.arcoboLogoIcon}
          alt=""
          src="/arcobo-logo@2x.svg"
          onClick={onArcoboLogoImageClick}
        />
      </div>
      <div className={styles.pageHeaderFrameChild} />
      <div className={styles.mainTextFrame}>
        <div className={styles.mainText}>
          {t('Main text')}
        </div>
      </div>
      <div className={styles.mainFrame}>
        <div className={styles.uploadFrame}>
          <div className={styles.modelInputFrame}>
            <div className={styles.dropzoneText}>{t('Dropzone header')}</div>
            <div {...getRootProps()} className={styles.dropzone}>
              <input {...getInputProps()} />
              {
                isDragActive ?
                  <p className={styles.dropzoneContent}>Drop the files here...</p> :
                  selectedFile ? <p className={styles.dropzoneContent}>{selectedFile}</p> : <p className={styles.dropzoneContent}>{t('Dropzone')}</p>
              }
            </div>
            <div className={styles.dropzoneText2}>
              <a href="#/whystl" target="_blank" rel="noopener noreferrer">{t('Dropzone footer link')}</a>
              {t('Dropzone footer')}             
            </div>
          </div>
          <div className={styles.parametersInputFrame}>
            <div className={styles.parametersFrame}>
              <div className={styles.qtyFrame}>
                <div className={styles.qtyLabel}>{t('Quantity')}</div>
                  <FormControl className={styles.qtySpinbox} sx={{ width: 100 }} variant="filled">
                    <TextField
                      type="number"
                      color="primary"
                      InputProps={{
                        inputProps: {
                          max: 99,
                          min: 1
                        }
                      }}
                      value={modelsQuantity}
                      onChange={handleModelsQuantityChange}
                      size="medium"
                    />
                  </FormControl>
                </div>
              <Autocomplete
                className={styles.materialselector}
                sx={{ width: "100%" }}
                disablePortal
                options={materialsLibrary}
                value={selectedMaterial}
                onChange={(event, newValue) => {
                  setSelectedMaterial(newValue);
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    color="primary"
                    label={t('Material')}
                    variant="outlined"
                    placeholder=""
                    helperText=""
                    inputProps={{ ...params.inputProps}}
                  />
                )}
                clearOnEscape={false}
                size="medium"
                clearIcon={null}
              />
            </div>
            <div className={styles.agreementFrame}>
              <div className={styles.spacer1}>
                <FormControlLabel
                  className={styles.termCheckbox}
                  label={
                    <nav>
                      <a href="#/userterms" target="_blank" rel="noopener noreferrer">{t('User agreement')}</a>
                    </nav>
                  }
                  control={
                    <Checkbox 
                      color="primary" 
                      size="medium"
                      onChange={(event) => setCheckboxChecked(event.target.checked)}
                    />
                  }
                />
              </div>
              <div className={styles.calculateButton} onClick={handleCalculateClick}>
                <div className={styles.calculateButtonLabel}>
                  {t('Calculate price button')}
                </div>
              </div>
            </div>
            <div className={styles.messageLabelWrapper}>
              <div className={styles.messageLabel}>
                {hintMessage && (
                  <p>{hintMessage}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.loadingframe}>
          <BeatLoader size={100} color={'#00ffff'} loading={loadingActive} />
      </div>
      {/* {enablePriceFrame && (
        <OrderFrame orderInfo={orderInformation} cancelOrder={triggerCancelOrder} showMessage={showMessage} />
      )} */}
        <div style={{ display: enablePriceFrame ? "block" : "none" }}>
          <OrderFrame orderInfo={orderInformation} cancelOrder={triggerCancelOrder} showMessage={showMessage} uuid={uuid} />
        </div>
      <div>
        <MessageFrame messageInfo={messageInformation}  />
      </div>
      <div className={styles.pageHeaderFrameChild} />
    </div>
  );
};

export default MainSection;