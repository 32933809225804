interface OrderInformation {
    filename: string;
    material: string;
    modelHash: string;
    modelWeight: string;
    modelVolume: string;
    modelPrintingTime: string;
    modelOrderTime: string;
    modelDimensions: string;
    modelQuantity: string;
    orderPrice: string;
    error: string; 
    warning: string;         
};

export const handleCalculateClickAction = (
    requestParams: any, 
    file: File | null,
    setOrderInformation: React.Dispatch<React.SetStateAction<OrderInformation>>,
    setCalculationComplete: React.Dispatch<React.SetStateAction<boolean>>) => {
    
    const fileReader = new FileReader();
    const formData = new FormData();
    formData.append("filedata", file);
    formData.append("requestParams", JSON.stringify(requestParams));

    fetch('/api/upload', {
        method: 'POST',
        body: formData,
    })
    .then(response => response.json())
    .then(data => {
        setOrderInformation(data);
        setCalculationComplete(true)
    })
    .catch(error => console.error('Error:', error));
}
