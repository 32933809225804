import { FunctionComponent, useEffect } from "react";
import styles from "./UserTerms.module.css";
const UserTerms: FunctionComponent = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <div className={styles.userTerms}>
      <div className={styles.agreementFrame} data-animate-on-scroll>
        <div className={styles.agreementHeaderEngFrame}>
          <b className={styles.agreementHeaderEng}>
            User agreement for the collection and processing of personal data
          </b>
        </div>
        <div className={styles.agreementEngFrame}>
          <div className={styles.ageementEngFrameContainer}>
            <p className={styles.iHereinafterReferred}>
              I (hereinafter referred to as the "Subject"), acting freely, by my
              own will and in my interest, give explicit, informed, and
              conscious consent to the Limited Liability Partnership "Arcobo" to
              collect and process my personal data and uploaded 3D models. I
              also confirm that I have read the Policy for the collection and
              processing of personal data, which is posted on the official
              website of the Partnership on the Internet.
            </p>
            <p className={styles.iHereinafterReferred}>
              This Consent applies to my email address and 3D models that I have
              uploaded to the site.
            </p>
            <p className={styles.iHereinafterReferred}>
              I provide this Consent for any actions regarding my personal data
              and 3D models that are necessary for the following purposes:
              collection, accumulation, storage, modification, addition, use,
              distribution, anonymization, blocking, destruction, and carrying
              out any other actions with my personal data according to the
              current legislation.
            </p>
            <p className={styles.iHereinafterReferred}>
              I give my consent for the processing of personal data and 3D
              models for the following purposes: fulfilling the obligations of
              the Partnership to me, providing feedback, including sending
              notifications, requests, information messages for the purpose of
              providing services, as well as sending emails and other forms of
              information to my email address. I also consent to the processing,
              storage, and analysis of the 3D models I uploaded for providing 3D
              printing services and optimizing this service, providing me with
              technical support for products and services, evaluation and
              improvement of the quality of services, operation of the
              Partnership, development of new services, and conducting
              statistical research.
            </p>
            <p className={styles.iHereinafterReferred}>
              I acknowledge and confirm that the Partnership, to achieve the
              above goals, has the right to transfer my personal data and 3D
              models to a third party, with adherence to the requirements of
              legislation on personal data and their protection.
            </p>
            <p className={styles.iHereinafterReferred}>
              I provide this consent without limiting its duration, but no
              longer than is provided by law or for the purposes of collecting
              and processing personal data and 3D models carried out by the
              Partnership.
            </p>
            <p className={styles.iCanRevoke}>
              I can revoke my consent to the processing of personal data and 3D
              models at any time during its validity period by sending a written
              appeal to the Partnership.
            </p>
          </div>
        </div>
        <div className={styles.agreementHeaderEngFrame}>
          <b className={styles.agreementHeaderEng}>
            Пользовательское соглашение
          </b>
        </div>
        <div className={styles.agreementRusFrame}>
          <div className={styles.ageementEngFrameContainer}>
            <p className={styles.iHereinafterReferred}>
              Я (далее – Субъект), действуя свободно, своей волей и в своем
              интересе, даю конкретное, информированное и сознательное согласие
              Товариществу с ограниченной ответственностью «Аркобо» (далее –
              «Товарищество») на сбор, обработку моих персональных данных и
              загруженных 3D моделей, а также подтверждаю факт ознакомления с
              Политикой сбора и обработки персональных данных, размещенной на
              официальном сайте Товарищества в сети Интернет.
            </p>
            <p className={styles.iHereinafterReferred}>
              Настоящее Согласие распространяется на следующие персональные
              данные:
            </p>
            <ul className={styles.d1}>
              <li className={styles.li}>адрес электронной почты;</li>
              <li className={styles.li}>
                3D модели, загруженные мною на сайт.
              </li>
            </ul>
            <p className={styles.iHereinafterReferred}>
              Настоящее Согласие предоставляется на осуществление любых действий
              по отношению к моим персональным данным и 3D моделям, которые
              необходимы для достижения указанных ниже целей, включая: сбор,
              накопление, хранение, изменение, дополнение, использование,
              распространение, обезличивание, блокирование, уничтожение, а также
              осуществление любых иных действий с моими персональными данными с
              соблюдением требований действующего законодательства. Согласие на
              обработку персональных данных и 3D моделей дается мной для
              следующих целей:
            </p>
            <p className={styles.iHereinafterReferred}>
              выполнения обязательств Товарищества передо мной, обратной связи
              со мной, в том числе направление уведомлений, запросов,
              информационных сообщений в целях оказания услуг, а также для
              направления электронных писем и иных форм направления/получения
              информации по адресу электронной почты, а также в целях обработки
              запросов и заявок от меня; обработки, хранения и анализа
              загруженных мной 3D моделей для оказания услуг 3D печати и
              оптимизации данной услуги; предоставления мне технической
              поддержки по продуктам и услугам; оценки и улучшения качества
              услуг, работы Товарищества, разработки новых услуг, продвижения
              услуг; статистических исследований.
            </p>
            <p className={styles.iHereinafterReferred}>
              Настоящим я признаю и подтверждаю, что Товарищество для достижения
              указанных выше целей вправе передавать мои персональные данные и
              3D модели третьему лицу, с соблюдением требований законодательства
              о персональных данных и их защите.
            </p>
            <p className={styles.iHereinafterReferred}>
              Настоящее согласие выдано без ограничения срока его действия, но
              не дольше, чем это предусмотрено законодательством или для целей
              сбора и обработки персональных данных и 3D моделей, осуществляемых
              Товариществом.
            </p>
            <p className={styles.iCanRevoke}>
              Согласие на обработку персональных данных и 3D моделей может быть
              отозвано мной в любой момент в период его действия путем
              направления письменного обращения к Товариществу.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTerms;
