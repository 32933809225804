import { FunctionComponent, useEffect, useState } from "react";
import { TextField, FormControlLabel, Checkbox } from "@mui/material";
import styles from "./OrderFrame.module.css";

interface OrderInfo {
  orderInfo: any;
  cancelOrder: () => void;
  showMessage: any;
  uuid: any;
}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

import { useTranslation } from 'react-i18next';

const OrderFrame: FunctionComponent<OrderInfo> = ({orderInfo, cancelOrder, showMessage, uuid}) => {
  const { t, i18n } = useTranslation();

  const [isCheckboxCheck, setCheckboxChecked] = useState(false);
  const [hintMessage, setHintMessage] = useState(null);
  const [email, setEmail] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handleOrderButtonClick = () => {
    if (validateEmail(email)) {
      if (hintMessage != null) {
        setHintMessage(null)
      }
        
      fetch('/api/order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uuid: uuid,
          modelHash: orderInfo.modelHash,
          email: email
        }),
      })
      .then(response => response.json())
      .then(data => {})
      .catch(error => console.error('Error:', error));

      //Send to backend!!!
      let message = {
        messageHeader: t("Thank you"),
        messageBody: t("Order accepted"),
        buttonText: t("Close"),
      };

      showMessage(message)
      cancelOrder();
    } else {
      setHintMessage(t("Email is incorrect"))
    }
  }

  // FOR DEBUG:
  // const handleOrderButtonClick = () => {
  //   fetch('/api/order', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       uuid: uuid,
  //       modelEtag: orderInfo.modelEtag,
  //       email: "mi@lwms.ru"
  //     }),
  //   })
  //   .then(response => response.json())
  //   .then(data => {})
  //   .catch(error => console.error('Error:', error));

  //   //Send to backend!!!
  //   let message = {
  //     messageHeader: t("Thank you"),
  //     messageBody: t("Order accepted"),
  //     buttonText: t("Close"),
  //     };

  //   showMessage(message)
  //   cancelOrder();
  // }

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <div className={styles.orderFrame} data-animate-on-scroll>
      <div className={styles.priceFrameHeader}>
        <div className={styles.cancelButton} onClick={cancelOrder}>
          <div className={styles.cross}>x</div>
        </div>
      </div>
      <div className={styles.filenameFrame}>
        <div className={styles.cross}>{t("File")}</div>
        <div className={styles.filenameLabelFrame}>
          <div className={styles.filenameVarLabel}>{orderInfo && orderInfo.filename}</div>
        </div>
      </div>
      <div className={styles.weightvolumeFrame}>
        <div className={styles.weightFrame}>
          <div className={styles.weightLabel}>{t("Weight")}</div>
          <div className={styles.cross}>{orderInfo && orderInfo.modelWeight}</div>
        </div>
        <div className={styles.volumeFrame}>
          <div className={styles.weightLabel}>{t("Volume")}</div>
          <div className={styles.volumeVarLabel}>{orderInfo && orderInfo.modelVolume}</div>
        </div>
      </div>
      <div className={styles.timeFrame}>
        <div className={styles.weightFrame}>
          <div className={styles.weightLabel}>{t("Printing time")}</div>
          <div className={styles.volumeVarLabel}>{orderInfo && orderInfo.modelPrintingTime}</div>
        </div>
        <div className={styles.weightFrame}>
          <div className={styles.weightLabel}>{t("Order time")}</div>
          <div className={styles.volumeVarLabel}>{orderInfo && orderInfo.modelOrderTime}</div>
        </div>
      </div>
      <div className={styles.dimensionsFrame}>
        <div className={styles.weightLabel}>{t("External dimensions")}</div>
        <div className={styles.volumeVarLabel}>{orderInfo && orderInfo.modelDimensions}</div>
      </div>
      <div className={styles.dimensionsFrame}>
        <div className={styles.weightLabel}>{t("Quantity in order")}</div>
        <div className={styles.volumeVarLabel}>{orderInfo && orderInfo.modelQuantity}</div>
      </div>
      <div className={styles.filenameFrame}>
        <div className={styles.cross}>{t("Selected alloy")}</div>
        <div className={styles.filenameLabelFrame}>
          <div className={styles.filenameVarLabel}>{orderInfo && orderInfo.material}</div>
        </div>
      </div>
      <div className={styles.lineSeparator} />
      <div className={styles.priceFrame}>
        <div className={styles.priceLabel}>{t("Total price")}</div>
        <b className={styles.priceVarLabel}>${orderInfo && orderInfo.orderPrice}</b>
      </div>
      <div className={styles.emailFrame}>
        <div className={styles.weightLabel}>{t("Insert your email")}</div>
        <TextField
          className={styles.emailLineedit}
          color="primary"
          variant="outlined"
          type="text"
          label="example@mail.com"
          placeholder="Placeholder"
          size="medium"
          margin="none"
          value={email}
          onChange={handleEmailChange}
        />
      </div>
      {hintMessage && (
          <p className={styles.hintMessage}>{hintMessage}</p>
      )}
      <div className={styles.orderApproveFrame}>
        {/* <div className={styles.confirmTermsFrame}>
          <FormControlLabel
            label=""
            control={<Checkbox
               color="primary"
               size="medium"
               onChange={(event) => setCheckboxChecked(event.target.checked)}
            />}
          />
          <div className={styles.termsConfirmLabel}>{t("Confirm hint")}</div>
        </div> */}
        <div className={styles.orderbutton} onClick={handleOrderButtonClick}>
          <div className={styles.orderButtonLabel}>{t("Complete order")}</div>
        </div>
      </div>
    </div>
  );
};

export default OrderFrame;