import { FunctionComponent, useEffect } from "react";
import styles from "./WhySTL.module.css";
const WhySTL: FunctionComponent = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <div className={styles.whyStl}>
      <div className={styles.whyStlFrame} data-animate-on-scroll>
        <div className={styles.whyStlHeader}>
          <b className={styles.whyStlHeader1}>
            Why we now use only STL-files for online calculation?
          </b>
        </div>
        <div className={styles.whyStlBody}>
          <div className={styles.whyStlBodyContainer}>
            <p className={styles.theStandardTriangle}>
              The Standard Triangle Language (STL), initially developed for the
              rapid prototyping industry, has long become the de facto standard
              in the exchange of 3D models. But why has STL specifically gained
              such popularity for this purpose?
            </p>
            <p className={styles.theStandardTriangle}>1. Universality</p>
            <p className={styles.theStandardTriangle}>
              STL is the most widely accepted and commonly used format in the 3D
              printing industry. It is supported by nearly all 3D printers and
              most 3D modeling software. This makes STL the perfect format for
              exchanging 3D models between different programs and platforms.
            </p>
            <p className={styles.theStandardTriangle}>2. Simplicity</p>
            <p className={styles.theStandardTriangle}>
              STL files describe the surfaces of 3D models using only triangles,
              which leads to a relatively simple data structure. This simplifies
              the processing and interpretation of STL data, making this format
              convenient for many applications.
            </p>
            <p className={styles.theStandardTriangle}>3. Adequacy</p>
            <p className={styles.theStandardTriangle}>
              For most 3D printing purposes, the detail offered by STL is more
              than sufficient. STL files describe the external geometry of the
              object and do not include information about textures, colors, or
              materials. This could be considered a disadvantage in some areas,
              but for the exchange of models in 3D printing, this is considered
              adequate.
            </p>
            <p className={styles.theStandardTriangle}>4. Reliability</p>
            <p className={styles.theStandardTriangle}>
              Due to their simplicity and universality, STL files have a high
              degree of reliability when transferred between various systems and
              programs. The likelihood of errors or loss of information when
              exchanging models in the STL format is significantly lower than
              when using more complex formats.
            </p>
            <p className={styles.theStandardTriangle}>
              5. Compatibility with Slicing Software
            </p>
            <p className={styles.theStandardTriangle}>
              Slicing software, which is used to prepare 3D models for printing,
              usually prefers the STL format. This is due to the fact that STL
              describes 3D models in the form of triangular polygons, which is
              perfect for the process of "slicing" the model into layers.
            </p>
            <p className={styles.theStandardTriangle}>
              6. Free and Consistent Across Platforms
            </p>
            <p className={styles.theStandardTriangle}>
              One of the biggest advantages of STL is that it is free and
              consistently generated across various CAD systems. There aren’t
              many versions that are hard to distinguish from one another, as
              can be the case with other file formats. This uniformity
              eliminates confusion and ensures smooth and consistent results
              when your file is used on different systems or shared with others.
            </p>
            <p className={styles.inConclusionStl}>
              In conclusion, STL has advantages in the field of 3D model
              exchange due to its universality, simplicity, adequacy,
              reliability, compatibility with 3D printing hardware and software,
              and consistent generation across CAD systems. This makes it the
              ideal choice for the exchange and distribution of 3D models.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhySTL;
