import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./MessageFrame.module.css";

interface MessageInformation {
  messageInfo: any;
}

const MessageFrame: FunctionComponent<MessageInformation> = ({messageInfo}) => {
  const [isVisualize, setVisualize] = useState(false);

  const handleCloseClick = () => {
    setVisualize(false)
  };

  useEffect(() => {
    if (messageInfo !== null) {
      setVisualize(true)
    }
  }, [messageInfo]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, [isVisualize]);

  return (
    <div>
      { isVisualize && (
        <div>
          <div className={styles.overlay}></div>
          <div className={styles.messageFrame} data-animate-on-scroll>
            <div className={styles.messageFrameHeader}>
              <div className={styles.cancelButton} onClick={handleCloseClick}>
                <div className={styles.cross}>x</div>
              </div>
            </div>
            <div className={styles.messageHeaderFrame}>
              <b className={styles.headerVarLabel}>{messageInfo.messageHeader}</b>
            </div>
            <div className={styles.messageBodyFrame}>
              <b className={styles.messageVarLabel}>
                {messageInfo.messageBody}
              </b>
            </div>
            <div className={styles.footerFrame}>
              <div className={styles.messageButton} onClick={handleCloseClick}>
                <div className={styles.messageVarButton}>{messageInfo.buttonText}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    
  );
};

export default MessageFrame;
